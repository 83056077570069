<template>
  <div class="typeContent">
    <div class="typeContentList">
      <div
        class="typeContentItem"
        v-for="(item, index) in contentData"
        :key="index"
      >
        <!-- <div class="typeHeader">
          <div class="khung khung01">
            <div class="khung_before">{{ item.title }}</div>
            <span>【{{ item.typeName }}】</span>
            <div class="khung_after">{{ item.webAdress }}</div>
          </div>
        </div> -->
        <!-- <table class="ly1 ys">
          <tbody>
            <tr>
              <td style="line-height: normal">
                <span
                  style="
                    background-color: #57d8a3;
                    border-radius: 5px;
                    color: #000;
                  "
                  v-for="(ele, i) in item.introduceList"
                  :key="i"
                  >{{ ele }}</span
                >
              </td>
            </tr>
            <tr v-if="item.qx||item.hx">
              <td style="line-height: 20px">
                <strong
                  >前肖：{{item.qx}}<br />后肖：{{item.hx}}</strong
                >
              </td>
            </tr>
            <tr v-for="(ele, i) in item.list" :key="i">
              <td>
                <b>{{ ele.period }}</b
                >:{{ ele.lotteryType }}

                <template v-if="ele.betType">
                  【<font>{{ ele.betType }}</font
                  >】
                </template>

                开:<span v-if="ele.lotteryResults.isWinning">{{
                  ele.lotteryResults.content
                }}</span>
                <template v-else>{{ ele.lotteryResults.content }}</template>
                <div v-if="ele.jp24List">
                  <div v-for="(jpele, jpindex) in ele.jp24List" :key="jpindex">
                    【{{ jpele }}】
                  </div>
                </div>
              </td>
            </tr>
          </tbody>
        </table> -->
      
        <!-- <div v-html="item.valueStr"></div> -->
      </div>
    </div>
  
  </div>
</template>

<script>
import { mapState } from "vuex";
export default {
  computed: {
    ...mapState(["htmlObj"]),
  },
  name: "bankaccount",
  data() {
    return {
      html: ``,
      contentData: [
        {
          title: "王中王", //标题名称
          typeName: "大小中特", //标题类型
          webAdress: "123321.com", //网址
          introduceList: [
            "要看要下，赶紧赶快！好料不要错过",
            "机会不是天天有，该出手就出手",
          ], //介绍----一行一组数据
          list: [
            {
              period: "105", //期数
              lotteryType: "大小中特", //彩票类型
              betType: "大大小小", //投注类型
              lotteryResults: {
                isWinning: true, //是否中奖。true为中奖，false为未中奖
                content: "羊46中", //开奖结果内容
              }, //开奖结果
            },
            {
              period: "105", //期数
              lotteryType: "大小中特", //彩票类型
              betType: "大大小小", //投注类型
              lotteryResults: {
                isWinning: false, //是否中奖。true为中奖，false为未中奖
                content: "羊46中", //开奖结果内容
              }, //开奖结果
            },
            {
              period: "105", //期数
              lotteryType: "大小中特", //彩票类型
              betType: "大大小小", //投注类型
              lotteryResults: {
                isWinning: true, //是否中奖。true为中奖，false为未中奖
                content: "羊46中", //开奖结果内容
              }, //开奖结果
            },
            {
              period: "105", //期数
              lotteryType: "大小中特", //彩票类型
              betType: "大大小小", //投注类型
              lotteryResults: {
                isWinning: false, //是否中奖。true为中奖，false为未中奖
                content: "羊46中", //开奖结果内容
              }, //开奖结果
            },
            {
              period: "105", //期数
              lotteryType: "大小中特", //彩票类型
              betType: "大大小小", //投注类型
              lotteryResults: {
                isWinning: true, //是否中奖。true为中奖，false为未中奖
                content: "羊46中", //开奖结果内容
              }, //开奖结果
            },
            {
              period: "105", //期数
              lotteryType: "大小中特", //彩票类型
              betType: "大大小小", //投注类型
              lotteryResults: {
                isWinning: false, //是否中奖。true为中奖，false为未中奖
                content: "羊46中", //开奖结果内容
              }, //开奖结果
            },
            {
              period: "105", //期数
              lotteryType: "大小中特", //彩票类型
              betType: "大大小小", //投注类型
              lotteryResults: {
                isWinning: true, //是否中奖。true为中奖，false为未中奖
                content: "羊46中", //开奖结果内容
              }, //开奖结果
            },
            {
              period: "105", //期数
              lotteryType: "大小中特", //彩票类型
              betType: "大大小小", //投注类型
              lotteryResults: {
                isWinning: false, //是否中奖。true为中奖，false为未中奖
                content: "羊46中", //开奖结果内容
              }, //开奖结果
            },
            {
              period: "105", //期数
              lotteryType: "大小中特", //彩票类型
              betType: "大大小小", //投注类型
              lotteryResults: {
                isWinning: true, //是否中奖。true为中奖，false为未中奖
                content: "羊46中", //开奖结果内容
              }, //开奖结果
            },
            {
              period: "105", //期数
              lotteryType: "大小中特", //彩票类型
              betType: "大大小小", //投注类型
              lotteryResults: {
                isWinning: false, //是否中奖。true为中奖，false为未中奖
                content: "羊46中", //开奖结果内容
              }, //开奖结果
            },
            {
              period: "105", //期数
              lotteryType: "大小中特", //彩票类型
              betType: "大大小小", //投注类型
              lotteryResults: {
                isWinning: true, //是否中奖。true为中奖，false为未中奖
                content: "羊46中", //开奖结果内容
              }, //开奖结果
            },
            {
              period: "105", //期数
              lotteryType: "大小中特", //彩票类型
              betType: "大大小小", //投注类型
              lotteryResults: {
                isWinning: false, //是否中奖。true为中奖，false为未中奖
                content: "羊46中", //开奖结果内容
              }, //开奖结果
            },
          ],
        },
        {
          title: "王中王", //标题名称
          typeName: "精准七肖", //标题类型
          webAdress: "777777.com", //网址
          introduceList: [
            "要看要下，赶紧赶快！好料不要错过",
            "机会不是天天有，该出手就出手",
          ], //介绍----一行一组数据
          list: [
            {
              period: "105", //期数
              lotteryType: "七肖", //彩票类型
              betType: "龙猪猴马牛蛇鼠", //投注类型
              lotteryResults: {
                isWinning: true, //是否中奖。true为中奖，false为未中奖
                content: "蛇48中", //开奖结果内容
              }, //开奖结果
            },
            {
              period: "105", //期数
              lotteryType: "七肖", //彩票类型
              betType: "龙猪猴马牛蛇鼠", //投注类型
              lotteryResults: {
                isWinning: false, //是否中奖。true为中奖，false为未中奖
                content: "蛇48中", //开奖结果内容
              }, //开奖结果
            },
            {
              period: "105", //期数
              lotteryType: "七肖", //彩票类型
              betType: "龙猪猴马牛蛇鼠", //投注类型
              lotteryResults: {
                isWinning: true, //是否中奖。true为中奖，false为未中奖
                content: "蛇48中", //开奖结果内容
              }, //开奖结果
            },
            {
              period: "105", //期数
              lotteryType: "七肖", //彩票类型
              betType: "龙猪猴马牛蛇鼠", //投注类型
              lotteryResults: {
                isWinning: false, //是否中奖。true为中奖，false为未中奖
                content: "蛇48中", //开奖结果内容
              }, //开奖结果
            },
            {
              period: "105", //期数
              lotteryType: "七肖", //彩票类型
              betType: "龙猪猴马牛蛇鼠", //投注类型
              lotteryResults: {
                isWinning: true, //是否中奖。true为中奖，false为未中奖
                content: "蛇48中", //开奖结果内容
              }, //开奖结果
            },
            {
              period: "105", //期数
              lotteryType: "七肖", //彩票类型
              betType: "龙猪猴马牛蛇鼠", //投注类型
              lotteryResults: {
                isWinning: false, //是否中奖。true为中奖，false为未中奖
                content: "蛇48中", //开奖结果内容
              }, //开奖结果
            },
            {
              period: "105", //期数
              lotteryType: "七肖", //彩票类型
              betType: "龙猪猴马牛蛇鼠", //投注类型
              lotteryResults: {
                isWinning: true, //是否中奖。true为中奖，false为未中奖
                content: "蛇48中", //开奖结果内容
              }, //开奖结果
            },
            {
              period: "105", //期数
              lotteryType: "七肖", //彩票类型
              betType: "龙猪猴马牛蛇鼠", //投注类型
              lotteryResults: {
                isWinning: false, //是否中奖。true为中奖，false为未中奖
                content: "蛇48中", //开奖结果内容
              }, //开奖结果
            },
          ],
        },
        {
          title: "王中王", //标题名称
          typeName: "平特一尾", //标题类型
          webAdress: "99999.com", //网址
          introduceList: [
            "中了真的是太开心了，跟上的家人啊一定赚大了",
            "加油哦大家，今天赚了明天就可以睡个大濑觉了",
          ], //介绍----一行一组数据
          list: [
            {
              period: "105", //期数
              lotteryType: "平特一尾", //彩票类型
              betType: "55555尾", //投注类型
              lotteryResults: {
                isWinning: true, //是否中奖。true为中奖，false为未中奖
                content: "蛇48中", //开奖结果内容
              }, //开奖结果
            },
            {
              period: "105", //期数
              lotteryType: "平特一尾", //彩票类型
              betType: "55555尾", //投注类型
              lotteryResults: {
                isWinning: false, //是否中奖。true为中奖，false为未中奖
                content: "蛇48中", //开奖结果内容
              }, //开奖结果
            },
            {
              period: "105", //期数
              lotteryType: "平特一尾", //彩票类型
              betType: "1111尾", //投注类型
              lotteryResults: {
                isWinning: true, //是否中奖。true为中奖，false为未中奖
                content: "蛇48中", //开奖结果内容
              }, //开奖结果
            },
            {
              period: "105", //期数
              lotteryType: "平特一尾", //彩票类型
              betType: "999999尾", //投注类型
              lotteryResults: {
                isWinning: false, //是否中奖。true为中奖，false为未中奖
                content: "蛇48中", //开奖结果内容
              }, //开奖结果
            },
            {
              period: "105", //期数
              lotteryType: "平特一尾", //彩票类型
              betType: "66666尾", //投注类型
              lotteryResults: {
                isWinning: true, //是否中奖。true为中奖，false为未中奖
                content: "蛇48中", //开奖结果内容
              }, //开奖结果
            },
            {
              period: "105", //期数
              lotteryType: "平特一尾", //彩票类型
              betType: "77777尾", //投注类型
              lotteryResults: {
                isWinning: false, //是否中奖。true为中奖，false为未中奖
                content: "蛇48中", //开奖结果内容
              }, //开奖结果
            },
            {
              period: "105", //期数
              lotteryType: "平特一尾", //彩票类型
              betType: "8888尾", //投注类型
              lotteryResults: {
                isWinning: true, //是否中奖。true为中奖，false为未中奖
                content: "蛇48中", //开奖结果内容
              }, //开奖结果
            },
            {
              period: "105", //期数
              lotteryType: "平特一尾", //彩票类型
              betType: "22222尾", //投注类型
              lotteryResults: {
                isWinning: false, //是否中奖。true为中奖，false为未中奖
                content: "蛇48中", //开奖结果内容
              }, //开奖结果
            },
          ],
        },
        {
          title: "王中王", //标题名称
          typeName: "精品24码", //标题类型
          webAdress: "22222.com", //网址
          introduceList: ["六合无绝对，但是有高手，资料期期免费更新"], //介绍----一行一组数据
          list: [
            {
              period: "105", //期数
              lotteryType: "精品24码", //彩票类型
              betType: "",
              jp24List: [
                "01.02.04.06.10.12.14.16.18.20.21.23",
                "24.28.30.32.36.37.38.40.42.46.47.48",
              ],
              lotteryResults: {
                isWinning: true, //是否中奖。true为中奖，false为未中奖
                content: "蛇48中", //开奖结果内容
              }, //开奖结果
            },
            {
              period: "105", //期数
              lotteryType: "精品24码", //彩票类型
              betType: "",
              jp24List: [
                "01.02.04.06.10.12.14.16.18.20.21.23",
                "24.28.30.32.36.37.38.40.42.46.47.48",
              ],
              lotteryResults: {
                isWinning: true, //是否中奖。true为中奖，false为未中奖
                content: "蛇48中", //开奖结果内容
              }, //开奖结果
            },
            {
              period: "105", //期数
              lotteryType: "精品24码", //彩票类型
              betType: "",
              jp24List: [
                "01.02.04.06.10.12.14.16.18.20.21.23",
                "24.28.30.32.36.37.38.40.42.46.47.48",
              ],
              lotteryResults: {
                isWinning: true, //是否中奖。true为中奖，false为未中奖
                content: "蛇48中", //开奖结果内容
              }, //开奖结果
            },
            {
              period: "105", //期数
              lotteryType: "精品24码", //彩票类型
              betType: "",
              jp24List: [
                "01.02.04.06.10.12.14.16.18.20.21.23",
                "24.28.30.32.36.37.38.40.42.46.47.48",
              ],
              lotteryResults: {
                isWinning: true, //是否中奖。true为中奖，false为未中奖
                content: "蛇48中", //开奖结果内容
              }, //开奖结果
            },
            {
              period: "105", //期数
              lotteryType: "精品24码", //彩票类型
              betType: "",
              jp24List: [
                "01.02.04.06.10.12.14.16.18.20.21.23",
                "24.28.30.32.36.37.38.40.42.46.47.48",
              ],
              lotteryResults: {
                isWinning: true, //是否中奖。true为中奖，false为未中奖
                content: "蛇48中", //开奖结果内容
              }, //开奖结果
            },
            {
              period: "105", //期数
              lotteryType: "精品24码", //彩票类型
              betType: "",
              jp24List: [
                "01.02.04.06.10.12.14.16.18.20.21.23",
                "24.28.30.32.36.37.38.40.42.46.47.48",
              ],
              lotteryResults: {
                isWinning: true, //是否中奖。true为中奖，false为未中奖
                content: "蛇48中", //开奖结果内容
              }, //开奖结果
            },
          ],
        },
      ],
    };
  },
  mounted() {
     
    // 开奖列表
     this.getkjlbData();
  },
  methods: {
    async getkjlbData() {
      this.contentData = await this.$api.home.getkjlbData();
      this.contentData.forEach(item => {
        item.valueStr=decodeURIComponent(item.valueStr);
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.typeContentList {
  .typeHeader {
    .khung {
      line-height: 30px;
      background-image: url("@/assets/img/common/khung00.jpg");
      text-shadow: -1px 0 #000, 0 1px #000, 1px 0 #000, 0 -1px #000;
      background-position: center center;
      background-repeat: no-repeat;
      background-origin: padding-box;
      background-size: 250% 40px;
      font-size: 14pt;
      font-weight: 700;
      font-family: 楷体;
      border-collapse: collapse;
      text-align: center;
      border: 1px solid #c0c0c0;
      color: #fff;
      display: flex;
      align-items: center;
      justify-content: center;
    }
    .khung_before {
      color: #41e3ff;
    }

    .khung_after {
      color: #41e3ff;
    }
  }
  .typeContentItem {
  }
}
table {
  display: table;
  width: 100%;
  text-align: center;
  // border-collapse: collapse;
  // border-spacing: 0;
  box-sizing: border-box;
  text-indent: initial;
}
table {
  // border-collapse: collapse;
  // border-spacing: 0;
}
.ly1 td {
  font-family: Tahoma;
  font-weight: 700;
  color: black;
  border: 1px solid #ddd;
  line-height: 27px;
  font-size: 13pt;
  background-color: white;
}
table td {
  border: 1px solid #e5e5e5;
  word-break: break-all;
}
.ly1 b {
  color: blue;
}
.ly1 font {
  color: red;
}
.ys span {
  background-color: #ffff00;
  color: #ff0000;
}
</style>
